@import './components/upcomingMeetingsRow/upcoming-meetings-row';
@import './components/toggle/meetings-toggle';

.upcoming-meetings {
  width: 328px;
  min-width: 328px;
  height: calc(100vh - 193px);
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 8px;
  border: 1px solid #e3e7ed;
  background: #ffffff;

  &__header {
    height: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    background: #f8fafc;
    border-bottom: 1px solid #e3e7ed;

    &-title {
      color: #44536a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0;
    height: calc(100% - 55px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    &--sliced {
      overflow-y: hidden;
      height: calc(100% - 75px);
    }

    &-view-more {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #537bff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    &__loader {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: #646f87;
      text-align: center;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        right: 2px;
        width: 40px;
        height: 40px;
        z-index: 9;
        animation: loaderSpinAnimation 1s linear infinite;

        @keyframes loaderSpinAnimation {
          0% {
            transform: translate(-25%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-25%, -50%) rotate(360deg);
          }
        }
      }
    }

    &-date-title {
      color: #646f87;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      padding: 8px 16px;
      animation: fadeIn 0.4s linear;
    }

    &-no-data-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: #646f87;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      animation: fadeIn 0.4s linear;
    }

    &__not-connected {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: #646f87;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      animation: fadeIn 0.4s linear;

      &-title {
        color: #070b12;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }

      &-text {
        color: #646f87;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 6px 0px 16px;
      }

      &-link {
        display: flex;
        height: 32px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #e3e7ed;
        background: #ffffff;
        color: #070b12;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
          background: #f8fafc;
          border-color: #e3e7ed;
        }
      }
    }
  }

  &__load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 14px;
    left: 50%;
    transform: translate(calc(50% - 72px), 0px);

    &.hidden {
      display: none;
      visibility: hidden;
    }

    &-button {
      position: relative;
      appearance: none;
      display: inline-flex;
      align-items: center;
      min-width: 105px;
      width: max-content;
      height: 40px;
      min-height: 40px;
      gap: 8px;
      padding: 7px 12px;
      border-radius: 8px;
      color: $black-new-design;
      background-color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;

      border: 1px solid #e3e7ed;
      transition: background-color 150ms ease-in-out,
        border-color 150ms ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #f8fafc;

        .my-relationships__table-wrapper__load-more-button-tooltip {
          display: flex;
        }
      }

      &:active {
        background-color: $gray-100;
      }

      &-tooltip {
        display: none;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: max-content;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50px);
        color: $black-new-design;
        padding: 5px 16px;
        border-radius: 8px;
        border: 1px solid #e3e7ed;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
          0px 2px 6px 0px rgba(71, 107, 132, 0.15);
        z-index: 15;
        animation: tooltip-enter 0.15s linear;
      }

      &-loader {
        position: absolute;
        top: 50%;
        right: 31%;
        width: 20px;
        height: 20px;
        z-index: 9;
        animation: spinAnimation 1s linear infinite;

        @keyframes spinAnimation {
          0% {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          100% {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }
    }
  }

  &-scroll-element {
    width: 100%;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 60px);
    animation: $fadein;

    &-title {
      color: #070b12;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    &-img {
      margin: 26px 0;
      min-width: 230.661px;
      width: 230.661px;
      min-height: 190.781px;
      height: 190.781px;
      @include userSelectDisable;
    }

    &-clear-button {
      appearance: none;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 7px 12px;
      border-radius: 8px;
      color: $black-new-design;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      min-height: 40px;
      border: 1px solid #e3e7ed;
      background-color: #18222f;
      border-color: #18222f;
      color: #ffffff;
      transition: background-color 150ms ease-in-out,
        box-shadow 150ms ease-in-out, border-color 150ms ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #070b12;
        box-shadow: none;
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
