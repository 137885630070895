@import './components/LearningHubCard/learning-hub-card';
@import './components/WizardModal/wizard-modal';

.learning-hub {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100vh - 210px);
  position: relative;
  animation: $fadein;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      color: #070b12;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    &__info {
      display: flex;
      flex-direction: row;
      gap: 8px;

      &-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #070b12;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-right: 12px;
      }

      &-button {
        cursor: pointer;
        padding: 8px 12px;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 24px;
    padding-bottom: 84px;

    &--white-bg {
      width: 600px;
      height: 600px;
      background: white;

      & > img {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1539px) {
      justify-content: center;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(calc(-50% + 36px), 0);
    width: calc(100% - 72px);
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e3e7ed;
    background: #ffffff;
    box-shadow: 0px -4px 12px 0px rgba(60, 81, 92, 0.06);

    &__content {
      display: flex;
      gap: 48px;

      &-link {
        color: #646f87;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: darken(#646f87, 20%);
          text-decoration-line: underline;
          text-decoration-style: solid;
        }
      }
    }
  }
}
