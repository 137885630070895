.my-meetings-table {
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  color: $black-new-design;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 193px);

  &__scrollable {
    height: calc(100vh - 252px);
    max-height: calc(100vh - 252px);
  }

  &__empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: calc(100vh - 252px);
    color: $gray-300;
    padding: 0 30%;

    &-title {
      color: #070b12;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    &-text {
      margin: 4px 0 16px 0;
    }

    &-lock-icon {
      margin-bottom: 6px;
    }

    &-not-found-img {
      margin: 26px 0;
      min-width: 230.661px;
      width: 230.661px;
      min-height: 190.781px;
      height: 190.781px;
      @include userSelectDisable;
    }

    &--with-na-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: calc(100vh - 252px);
      color: $gray-300;
      padding: 0 30%;
      background: url('./icons/not-available-bg-img.png') no-repeat center;
      background-size: 100% 100%;
    }

    &-clear-filters-button {
      appearance: none;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 7px 12px;
      border-radius: 8px;
      color: $black-new-design;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      min-height: 40px;
      border: 1px solid #e3e7ed;
      background-color: #18222f;
      border-color: #18222f;
      color: #ffffff;
      transition: background-color 150ms ease-in-out,
        box-shadow 150ms ease-in-out, border-color 150ms ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #070b12;
        box-shadow: none;
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    transition: box-shadow 150ms ease-in-out;

    &:not(:last-child) {
      border-bottom: 1px solid #e3e7ed;
    }

    &:not(.my-meetings-table__row--header):hover {
      box-shadow: 0px 0px 4px 3px rgba(71, 107, 132, 0.08);
    }

    &--header {
      color: $gray-400;
      border-bottom: 1px solid #e3e7ed;

      &.disabled {
        pointer-events: none;
        @include userSelectDisable;
      }

      & > .my-meetings-table__cell {
        padding: 16px 24px;
        gap: 6px;
        justify-content: flex-start;
        background-color: #f8fafc;
        transition: background-color 150ms ease-in-out;
        user-select: none;
        color: $gray-400;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          background-color: $gray-100;
        }

        &--disabled {
          cursor: default;

          &:hover {
            background-color: #f8fafc;
          }
        }

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        & > div > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:nth-child(1) {
          width: 31%;
        }
        &:nth-child(2) {
          width: 22%;
          max-width: 22%;
        }
        &:nth-child(3) {
          width: 15%;
          max-width: 168px;
          max-width: 15%;
        }
        &:nth-child(4) {
          width: 33%;
        }
      }
    }
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:nth-child(1) {
      width: 31%;
    }
    &:nth-child(2) {
      width: 22%;
      max-width: 22%;
    }
    &:nth-child(3) {
      width: 15%;
      max-width: 168px;
      max-width: 15%;
    }
    &:nth-child(4) {
      width: 33%;
    }
  }

  &__button {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border: 1px solid #e3e7ed;
    border-radius: 8px;
    background-color: #ffffff;
    color: $black-new-design;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    transition: background-color 150ms ease-in-out,
      border-color 150ms ease-in-out, color 150ms ease-in-out;

    &:hover:not(:disabled) {
      background-color: #f8fafc;
    }

    &:active:not(:disabled) {
      background-color: $gray-100;
    }

    &:disabled {
      cursor: default;
      background-color: $gray-100;
      border-color: $gray-100;
      color: $gray-300;
    }

    &--in-progress:disabled {
      background-color: #f6fefa;
      border-color: #f6fefa;
      color: #10b981;
    }

    &--error:disabled {
      background-color: #fff5f6;
      border-color: #fff5f6;
      color: #b40d1e;
    }
  }

  &__source-icon {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  &__title {
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: 100%;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__account {
    appearance: none;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 24px;
    color: $black-new-design;
    cursor: pointer;
    width: 80%;
    max-width: calc(100% - 83px);

    & > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(90% - 32px);
    }

    &:hover {
      .my-meetings-table__edit-icon {
        opacity: 1;
      }
    }

    &:disabled {
      cursor: default;
    }
  }

  &__account-logo {
    border-color: #e3e7ed;
  }

  &__sort-icon {
    width: 12px;
    height: 12px;

    &--desc {
      & > path:last-child {
        fill: $gray-400;
      }
    }

    &--asc {
      & > path:first-child {
        fill: $gray-400;
      }
    }
  }

  &__edit-icon {
    width: 16px;
    height: 16px;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &__tooltip {
    width: auto;
  }
}
