@import './components/EventHeaderTab/event-header-tab';
@import './components/EventHeaderSearch/event-header-search';
@import './components/EventHeaderTitle/event-header-title';

.event-header {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0px 4px 8px 0px rgba(71, 107, 132, 0.15);
    position: relative;
    transition: z-index 0s ease-in-out 300ms;
    z-index: 1;

    &__left {
        display: flex;
        align-items: center;
        gap: 4px;
        max-width: calc(100% - 102px);
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__left-icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        
        & path {
            transition: fill 150ms ease-in-out;
        }

        &--loading {
            & path {
                fill: #E3E7ED;
            }
        }
    }

    &__right-icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
    }

    &__account-button-wrapper {
        position: relative;
    }

    &__account-button {
        appearance: none;
        background-color: #FFFFFF;
        border: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: $black-new-design;
        min-height: 36px;
        white-space: nowrap;

        & > .sound-wave-avatar {
            background-color: #FFFFFF;
        }

        &:not(:disabled) {
            cursor: pointer;

            &:hover {
                background-color: #F8FAFC;
            }
        }
    }

    &__account-logo {
        .sound-wave-avatar__placeholder-icon {
            width: 100%;
            height: 100%;
        }
    }

    &__disclaimer {
        position: absolute;
        top: calc(100% + 16px);
        left: 0;
        background-color: #FFFFFF;
        border-radius: 16px;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        width: 384px;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out, visibility 0s ease-in-out 300ms;
    }

    &__disclaimer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;
    }

    &__disclaimer-icon {
        width: 32px;
        height: 32px;
    }

    &__disclaimer-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: $black-new-design;
    }

    &__disclaimer-associate-button {
        appearance: none;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: 14px;
        line-height: 24px;
        color: $gray-300;
        cursor: pointer;
    }

    &__disclaimer-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(7, 11, 18, 0.22);
        z-index: 99;
        visibility: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out, visibility 0s ease-in-out 300ms;
    }

    &__separator {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: $gray-200;
        margin-right: 4px;
    }

    &--with-disclaimer {
        transition: none;
        z-index: 102;

        & .event-header__account-button {
            z-index: 100;
        }

        & .event-header__disclaimer {
            visibility: visible;
            opacity: 1;
            transition: opacity 300ms ease-in-out;
            animation: disclaimerAnimation 300ms ease-in-out;
        }

        & .event-header__disclaimer-overlay {
            visibility: visible;
            opacity: 1;
            transition: opacity 300ms ease-in-out;
            animation: disclaimerAnimation 300ms ease-in-out;
        }

        @keyframes disclaimerAnimation {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
}
