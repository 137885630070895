.wizard-modal {
  flex-direction: column;

  & > div > .sound-wave-modal__dialog-content {
    padding: 0;
  }

  & > div > .sound-wave-modal-header {
    align-items: center;
    border-bottom: none;
    animation: $fadein;

    & > div {
      position: relative;
      border-bottom: 1px solid transparent;
      padding: 32px;
      width: 100%;

      & > span:not(#subtitle) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 24px), -50%);
        color: #070b12;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 90px;
        white-space: nowrap;
        width: 80%;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        & > span#subtitle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% + 4px), 75%);
          color: #646f87;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }
  }

  &__content {
    width: 80vw;
    max-width: 1012px;
    min-height: calc(60vh + 73px);
    height: calc(60vh + 73px);
    padding: 24px 52px;
    overflow: auto;
    overflow-x: hidden;

    &__row-overflow {
      display: flex;
      flex-direction: column;
      gap: 32px;
      animation: $fadein;

      &.fade-enter {
        opacity: 0;
      }
      &.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms;
      }
      &.fade-exit {
        opacity: 1;
      }
      &.fade-exit-active {
        opacity: 0;
        transition: opacity 300ms;
      }

      &.slide-enter {
        transform: translateX(100%);
      }
      &.slide-enter-active {
        transform: translateX(0);
        transition: transform 300ms ease-in-out;
      }
      &.slide-exit {
        transform: translateX(0);
      }
      &.slide-exit-active {
        transform: translateX(-100%);
        transition: transform 300ms ease-in-out;
      }

      &--slide-back {
        &.slide-enter {
          transform: translateX(-100%);
        }
        &.slide-enter-active {
          transform: translateX(0);
          transition: transform 300ms ease-in-out;
        }
        &.slide-exit {
          transform: translateX(100%);
        }
        &.slide-exit-active {
          transform: translateX(0);
          transition: transform 300ms ease-in-out;
        }
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 316px;
      width: 100%;
      gap: 32px;

      &-img {
        cursor: default;
        @include userSelectDisable;
        @include userDragDisable;
        animation: $fadein;
      }

      &__info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        animation: $fadein;

        &-step-text {
          color: #070b12;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }

        &-title {
          color: #070b12;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
        }

        &-text {
          display: inline;
          color: #646f87;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          white-space: pre-line;

          &--bold {
            color: #646f87;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }

        &__extension-added {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          color: var(--Success-Success, #10b981);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          @include userSelectDisable;

          &-icon {
            cursor: default;
            min-width: 16px;
            min-height: 16px;
            width: 16px;
            height: 16px;
          }
        }

        &__link {
          display: flex;
          align-items: center;
          color: #537bff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          transition: all 150ms ease-in-out;
          cursor: pointer;
          gap: 8px;
          border-bottom: 1px solid #537bff;
          @include userSelectDisable;

          &-icon {
            @include userDragDisable;
          }

          &:hover {
            filter: brightness(0.8);
          }
        }

        &__works-with {
          display: flex;
          flex-direction: column;

          &-text {
            color: #070b12;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          &__icons {
            display: flex;
            gap: 8px;
            margin-top: 8px;

            &-icon {
              display: flex;
              align-items: center;
              padding: 8px;
              gap: 4px;
              border-radius: 8px;
              border: 1px solid #e3e7ed;
              background: #fff;
              @include userDragDisable;
              min-height: 32px;
              max-height: 32px;
              min-width: 32px;
              max-width: 32px;
              transition: all 300ms ease-in-out;
              cursor: pointer;

              &:hover {
                filter: brightness(0.98);
                transform: scale(1.3);
              }
            }
          }
        }

        &__note {
          display: flex;
          padding: var(--Size-Spacing-4, 12px);
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #fff3d6;
          background: #fffcf5;

          &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ad6e00;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            @include userSelectDisable;
            cursor: default;
            gap: 4px;

            &-icon {
              @include userDragDisable;
            }
          }

          &-text {
            color: #070b12;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 52px;
    border-top: 1px solid #e3e7ed;
    box-shadow: 0px -4px 12px 0px rgba(57, 69, 119, 0.07);
    animation: $fadein;

    &__pagination {
      display: flex;
      align-items: center;
      gap: 3px;

      &-dot {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background: #c6d1ff;
        transition: all 300ms ease-in-out;
        cursor: pointer;

        &:hover {
          background: darken(#c6d1ff, 3);
        }

        &--active {
          width: 8px;
          height: 8px;
          border-radius: 2px;
          background: #537bff;
          transition: all 300ms ease-in-out;
          cursor: default;
        }
      }
    }

    &-button {
      & > svg {
        transition: all 150ms ease-in-out;
      }

      &:disabled {
        & > svg {
          opacity: 0.3;
        }
      }

      &--next {
        width: 75px;

        &:disabled {
          & > svg {
            filter: invert(0.5);
          }
        }
      }
    }
  }

  &:nth-child(2n) {
    align-items: flex-start;
  }

  &-img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    max-height: calc(260px + 7%);
    max-width: calc(636px + 7%);
    transition: all 0.3s ease-in-out;
    @include userDragDisable;

    &:hover {
      filter: brightness(0.975);
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(16px, 16px);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    margin-top: 8px;
    max-width: 636px;
    display: none;

    &-text {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 175% */
    }

    &-icon {
      cursor: default;
    }
  }
}
