.rs-table-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e3e7ed;
  background-color: #ffffff;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  position: relative;

  &:hover:not(.title-row) {
    border-bottom: 1px solid #e3e7ed;
    background: #fff;
    box-shadow: 0px 0px 4px 3px rgba(71, 107, 132, 0.08);
    z-index: 1;
  }

  &.title-row {
    position: sticky;
    top: 0;
    z-index: 9;
  }

  &.expanded {
    border-color: #f8fafc;

    .rs-table-row__main-row {
      background: #f8fafc;

      &:hover {
        background: #f8fafc;
        box-shadow: none;
      }
    }
    .rs-table-row__subrow {
      padding: 0 24px 24px;
      height: 395px; // -65px each row
      border-top: none;
      border-bottom: 1px solid #e3e7ed;
      border-width: 1px;
      border-color: #e3e7ed;
      opacity: 1;
      transition: height 300ms ease-in-out, padding 300ms ease-in-out,
        border-color 300ms ease-in-out;

      &-overflow {
        padding: 0;
      }
    }

    .rs-table-row__main-row__expand-button {
      background: #f1f4f8;

      &:hover {
        background: #f1f4f8;
      }
    }

    .rs-table-row__main-row__expand-button-icon {
      transform: rotate(180deg);
    }
  }

  &__main-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 24px 24px 0px;
    height: 80px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover:not(.title) {
      background: #fff;
    }

    &.title {
      height: 50px;
      padding: 0;
      padding-right: 24px;
      background: #f8fafc;
      box-shadow: 0px 1px 12px 0px rgba(60, 81, 92, 0.05);
      transition: background 0s ease-in-out;

      &.hover-bg {
        background: #f1f4f8;
      }

      & > div {
        height: 50px;
        background: #f8fafc;

        &:hover {
          background: #f1f4f8;
        }

        &:nth-child(3) {
          padding-left: 24px;
        }
        &:nth-child(8) {
          min-width: 16%;
          max-width: 16%;
          padding-right: 48px;
        }

        &:nth-child(9) {
          pointer-events: none;
        }
      }

      & > div > div {
        color: $gray-400;
        font-weight: 600;
        cursor: pointer;
        @include userSelectDisable;
        text-wrap: nowrap;

        &:nth-child(1) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          overflow: visible;

          .rs-table-row__main-row__label-sort-icon {
            width: 12px;
            height: 12px;
            margin-left: 8px;
            transition: all 0.15s ease-in-out;

            &--desc {
              & > path:last-child {
                fill: $gray-400;
              }
            }

            &--asc {
              & > path:first-child {
                fill: $gray-400;
              }
            }
          }
        }
      }
    }

    &-tooltip {
      display: none;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #f2f2f2;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      width: max-content;
      left: 50%;
      top: 0;
      transform: translate(calc(-50% + 42px), 50px);
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid #44536a;
      background: rgba(7, 11, 18, 0.8);
      z-index: 15;
      color: $black-new-design;
      padding: 5px 16px;
      border-radius: 8px;
      border: 1px solid #e3e7ed;
      background-color: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
        0px 2px 6px 0px rgba(71, 107, 132, 0.15);
      animation: tooltip-enter 0.15s linear;
    }

    &__cell {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: center;
      color: #070b12;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      &:nth-child(1) {
        min-width: 17%;
        max-width: 17%;
        padding-left: 24px;

        & > div.rs-table-row__main-row__label {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 40px);
        }
      }
      &:nth-child(2) {
        min-width: 14%;
        max-width: 14%;
        padding-left: 24px;
      }
      &:nth-child(3) {
        min-width: 13%;
        max-width: 13%;
        padding-left: 24px;

        & > div.rs-table-row__main-row__label {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 5px);
        }
      }
      &:nth-child(4) {
        justify-content: center;
        min-width: 9%;
        max-width: 9%;
      }
      &:nth-child(5) {
        justify-content: center;
        min-width: 9%;
        max-width: 9%;
      }
      &:nth-child(6) {
        min-width: 12%;
        max-width: 12%;
        justify-content: center;

        & > div.rs-table-row__main-row__label {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 20px);
        }
      }
      &:nth-child(7) {
        justify-content: center;
        min-width: 10%;
        max-width: 10%;

        & > div.rs-table-row__main-row__label {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 20px);
        }
      }
      &:nth-child(8) {
        min-width: 10%;
        max-width: 10%;
        justify-content: center;
        // div {
        //   display: block;
        //   white-space: nowrap;
        // }
      }
      &:nth-child(9) {
        min-width: 6%;
        max-width: 6%;
        justify-content: flex-end;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-left: 4px;

      &:hover {
        .rs-table-row__main-row__icon-tooltip {
          display: flex;
        }
      }

      &-img {
        cursor: pointer;
      }

      &-tooltip {
        display: none;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #f2f2f2;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        // height: 25px;
        width: max-content;
        left: 50%;
        top: 0;
        transform: translate(-50%, 26px);
        z-index: 15;
        color: $black-new-design;
        padding: 5px 8px;
        border-radius: 8px;
        border: 1px solid #e3e7ed;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
          0px 2px 6px 0px rgba(71, 107, 132, 0.15);
        animation: tooltip-enter 0.15s linear;
      }
    }

    &-avatar {
      margin-right: 12px;
      border-radius: 8px;
    }

    &__label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;

      &:hover {
        .rs-table-row__main-row__label-tooltip {
          display: flex;
        }
      }

      &-tooltip {
        display: none;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #f2f2f2;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: max-content;
        left: 0;
        top: 0;
        transform: translate(0, 32px);
        z-index: 15;
        color: $black-new-design;
        padding: 5px 8px;
        border-radius: 8px;
        border: 1px solid #e3e7ed;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
          0px 2px 6px 0px rgba(71, 107, 132, 0.15);

        &.last-cell {
          left: unset;
          right: 0;
          transform: translate(-12px, -45px);
        }
      }
    }

    &__expand-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 12px;
      cursor: pointer;
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      padding: 6px;
      margin-right: 16px;
      margin-left: 16px;

      &:hover {
        background: #f8fafc;
      }

      &.disabled {
        opacity: 0.3;
        cursor: default;

        &:hover {
          background: transparent;
        }
      }
    }

    &__expand-button-icon {
      width: 16px;
      min-width: 16px;
      height: 16px;

      & > path {
        fill: #646f87;
      }
    }
  }

  &__subrow {
    display: flex;
    padding: 0 24px;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: #f9f9fc;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: height 300ms ease-in-out, padding 300ms ease-in-out,
      border-color 300ms ease-in-out, border-width 0ms ease-in-out 300ms,
      opacity 0ms ease-in-out 300ms;

    &-overflow {
      padding: 0;
      transition: padding 300ms ease-in-out;
      display: flex;
      width: 100%;
      border: 1px solid #e3e7ed;
      border-radius: 8px;
      background-color: #ffffff;
      overflow: hidden;
    }

    &__vertical-border-section {
      display: flex;
      padding: 16px 12px;
      height: 100%;
      // border-right: 1px solid #e3e7ed;
    }

    &__vertical-border-section-line {
      display: flex;
      height: 100%;
      width: 8px;
      background-color: #f1f4f8;
      border-radius: 4px;
    }

    &__rows-section {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow-y: auto;
      overflow-y: hidden;

      &__row {
        display: flex;
        background-color: #ffffff;
        border-bottom: 1px solid #e3e7ed;
        transition: all 0.1s linear;

        &:hover:not(.rs-table-row__subrow__rows-section__row--header) {
          border-bottom: 1px solid #dadfe6 !important;
          background: #fff;
          box-shadow: 0px 0px 4px 3px rgba(71, 107, 132, 0.08);
          z-index: 1;
        }

        &__cell {
          position: relative;
          padding: 16px 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          color: #070b12;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;

          &:nth-child(1) {
            width: calc(33% - 33px);
          }
          &:nth-child(2) {
            width: calc(33% + 21px);
          }
          &:nth-child(3) {
            width: 29.5%;
          }
          &:nth-child(4) {
            // width: 5%;
            width: 4.5%;
            justify-content: flex-end;
            white-space: nowrap;
          }

          &-event-icon {
            margin-right: 8px;
          }

          &-event-name {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 28px);
          }

          &-link {
            display: flex;
            height: 32px;
            width: 49px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid #e3e7ed;
            color: #070b12;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.15s ease-in-out;

            &-tooltip {
              display: none;
              position: absolute;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: #f2f2f2;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              width: max-content;
              right: 0;
              top: 0;
              transform: translate(-13px, -26px);
              z-index: 15;
              color: $black-new-design;
              padding: 5px 8px;
              border-radius: 8px;
              border: 1px solid #e3e7ed;
              background-color: #ffffff;
              box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
                0px 2px 6px 0px rgba(71, 107, 132, 0.15);
              animation: tooltip-enter 0.15s linear;
            }

            &:hover {
              background-color: #f8fafc;
              border-color: #e3e7ed;

              .rs-table-row__subrow__rows-section__row__cell-link-tooltip:not(.hidden) {
                display: flex;
              }
            }
          }

          &__participants {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            &-avatar {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(0%, -50%);

              &:nth-child(1) {
                left: 0%;
                z-index: 1;
              }
              &:nth-child(2) {
                left: calc(0% + 18px);
                z-index: 2;
              }
              &:nth-child(3) {
                left: calc(0% + 36px);
                z-index: 3;
              }
              &:nth-child(4) {
                border: none;
                background: transparent;
                left: calc(0% + 60px);
                z-index: 4;
                color: #95a1b6;
              }
            }
          }

          &-tooltip {
            display: none;
            position: absolute;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #f2f2f2;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: max-content;
            left: 50%;
            top: 0;
            transform: translate(calc(-50% - 30px), 38px);
            border-radius: 8px;
            border: 1px solid #44536a;
            background: rgba(7, 11, 18, 0.8);
            z-index: 15;
            color: $black-new-design;
            padding: 5px 8px;
            border-radius: 8px;
            border: 1px solid #e3e7ed;
            background-color: #ffffff;
            box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
              0px 2px 6px 0px rgba(71, 107, 132, 0.15);
            animation: tooltip-enter 0.15s linear;
          }
        }

        &:first-child {
          height: 44px;

          .rs-table-row__subrow__rows-section__row__cell {
            padding: 12px;
            color: #646f87;
            font-size: 12px;

            &:nth-child(4) {
              color: #537bff;
              cursor: pointer;
              transition: all 0.15s ease-in-out;

              &:hover {
                color: #325ce6;

                .rs-table-row__subrow__rows-section__row__cell-tooltip {
                  display: flex;
                }
              }
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &__skeleton {
    &::after {
      background: linear-gradient(
        90deg,
        rgba(227, 231, 237, 0.25) 25%,
        rgba(227, 231, 237, 1) 37%,
        rgba(227, 231, 237, 0.25) 63%
      ) !important;
    }
  }

  &__tooltip {
    width: auto;
  }
}
