.add-details-modal {
  & > div > .sound-wave-modal-header {
    color: #070b12;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__fieldset {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    & > div.form-field > input {
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &::placeholder {
        color: #bfc6d2;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    & > div.form-field > div.sound-wave-form__fields-group {
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      & > div > button {
        padding: 12px 8px;
      }

      & > input {
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        &::placeholder {
          color: #bfc6d2;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}
