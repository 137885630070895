@import './assets/fonts';
@import './styles/variables';
@import './components/components';
@import './pages/pages';
@import '~antd/dist/antd.css';
@import '~simplebar-react/dist/simplebar.min.css';

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  color: $text-primary;
}

.App {
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;

  &__feedback {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  &__scrollable {
    height: 100vh;
    width: 100%;
  }
}

.page {
  flex: 1;
  overflow-y: auto;
  overflow-x: visible;
  background-color: #f9f9fc;
  padding: 40px 40px;
  position: relative;

  &__content {
    padding: 28px 0;
  }

  &--new-design {
    overflow: unset;
    min-height: 100vh;
    background-color: #F8FAFC;
    padding: 0;

    & .page__content {
      padding: 24px 32px;
    }
  }

  &--new-design-white {
    background-color: #FFFFFF;
  }
}

.banners-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  position: sticky;
  top: 73px;
  z-index: 102;

  & > * {
    position: relative;
    animation: bannerOpenAnimation 300ms ease-in-out;
    z-index: calc(10 - var(--index));
    transition: margin-top 300ms ease-in-out;
  }

  & > * + * {
    margin-top: 4px;
  }

  & > :nth-child(1) {
    --index: 0;
  }

  & > :nth-child(2) {
    --index: 1;
  }

  & > .closing {
    animation: bannerCloseAnimation 300ms ease-in-out;
  }


  @keyframes bannerOpenAnimation {
    0% {
      transform: translateY(-100%);
      margin-bottom: -56px;
      margin-top: 0;
    }
    100% {
      transform: translateY(0);
      margin-bottom: 0;
    }
  }

  @keyframes bannerCloseAnimation {
    0% {
      transform: translateY(0);
      margin-bottom: 0;
    }
    100% {
      transform: translateY(-100%);
      margin-bottom: -56px;
      margin-top: 0;
    }
  }
}

.my-deals-page {
  flex: 1;
  overflow-y: auto;
  overflow-x: visible;
  background-color: #f8f8fb;
  padding: 25px 20px;
  position: relative;

  &-content {
    padding: 0 0;
  }

  &-darker {
    flex: 1;
    overflow-y: auto;
    overflow-x: visible;
    background-color: #f9f9fc;
    padding: 40px 40px;
    position: relative;

    &-content {
      padding: 28px 0;
    }
  }
}

.app-sumo-page {
  flex: 1;
  overflow-y: auto;
  overflow-x: visible;
  background-color: $main-background;
  padding: 40px 40px;
  position: relative;

  &-content {
    padding: 28px 0;
  }
}

@media screen and (max-width: $mobile) {
  .page {
    padding: 30px 1rem;

    &--new-design {
      padding: 0;
    }
  }
}

@media screen and (min-width: $desktop1280) and (max-width: ($desktop1440 - 1)) {
  .page {
    padding: 40px 40px;

    &--new-design {
      padding: 0;
    }
  }
}

@media screen and (min-width: $desktop1440) and (max-width: ($desktop2000 - 1)) {
  .page {
    padding: 40px 50px;

    &--new-design {
      padding: 0;
    }
  }

  .my-deals-page {
    padding: 25px 50px;
  }

  .my-deals-page-darker {
    padding: 40px 50px;
  }

  .app-sumo-page {
    padding: 40px 50px;
  }
}

@media screen and (min-width: $desktop2000) {
  .page {
    padding: 50px 250px;

    &--new-design {
      padding: 0;
    }
  }

  .my-deals-page {
    padding: 50px 180px;
  }

  .my-deals-page-darker {
    padding: 50px 180px;
  }

  .app-sumo-page {
    padding: 40px 50px;
  }
}

.routes {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active
  .ant-skeleton-content
  .ant-skeleton-paragraph
  > li {
  background: #84848408;
  background-size: 100% 100%;
  animation-duration: 1s;

  &::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(129, 129, 129, 0.24) 37%,
      rgba(255, 255, 255, 0) 63%
    );
    background-size: 100% 100%;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-moz-scrollbar {
  width: 6px;
}

::-ms-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-moz-scrollbar-track {
  background: transparent;
}

::-ms-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $text-low-emp;
  border-radius: 4px;
}

::-moz-scrollbar-thumb {
  background: $text-low-emp;
  border-radius: 4px;
}

::-ms-scrollbar-thumb {
  background: $text-low-emp;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dbdbdb;
}

::-moz-scrollbar-thumb:hover {
  background: #dbdbdb;
}

::-ms-scrollbar-thumb:hover {
  background: #dbdbdb;
}

.d-none {
  display: none !important;
}
