.sound-wave-sidebar {
  width: 72px;
  background-color: $black-new-design;
  color: #ffffff;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  &__group {
    display: flex;

    &--grow {
      flex-grow: 1;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    &--learning-hub {
      margin-top: auto;
    }
  }

  &__nav-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 150ms ease-in-out;

    &:hover {
      background-color: $gray-400;
    }
  }

  &__tooltip {
    width: auto;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__support {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap;
  }

  &__support-button {
    appearance: none;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: $gray-400;
    }
  }
}
