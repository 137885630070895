.learning-hub-card {
  width: calc(50% - 16px);
  max-width: calc(50% - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  gap: 16px;
  cursor: pointer;
  @include userSelectDisable;

  &:nth-child(2n) {
    align-items: flex-start;
  }

  &-img {
    width: 100%;
    height: 100%;
    max-height: 260px;
    max-width: 636px;
    transition: all 0.3s ease-in-out;
    background-size: cover;
    min-height: 260px;
    max-height: 260px;
    @include userDragDisable;

    &:hover {
      filter: brightness(0.975);
    }
  }

  &__label {
    width: 100%;
    max-width: calc(636px + 7%);
    max-width: 636px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    max-width: 636px;
    // position: absolute;
    // top: 0;
    // left: 0;
    // transform: translate(16px, 16px);
    // display: none;

    &__name {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      &-icon {
        cursor: default;
      }

      &-text {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
    }

    &__icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &-icon {
        min-width: 34px;
        min-height: 34px;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        gap: 4px;
        border-radius: 8px;
        border: 1px #e3e7ed solid;
        background: #ffffff;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    min-width: 422px;
    max-width: 422px;

    &-img {
      min-width: 422px;
      max-width: 422px;
    }

    &__label {
      max-width: 422px;
    }

    &.setup-c {
      & > .learning-hub-card-img {
        background: url(./assets/setup-1920.png) no-repeat center center;
      }
    }
    &.q-for-email-c {
      & > .learning-hub-card-img {
        background: url(./assets/q-for-email-1920.png) no-repeat center center;
      }
    }
    &.soundwave-c {
      & > .learning-hub-card-img {
        background: url(./assets/soundwave-1920.png) no-repeat center center;
      }
    }
    &.temper-c {
      & > .learning-hub-card-img {
        background: url(./assets/temper-1920.png) no-repeat center center;
      }
    }
  }

  @media screen and (min-width: 1660px) and (max-width: 1919px) {
    min-width: 486px;
    max-width: 486px;

    &-img {
      min-width: 487px;
      max-width: 487px;
    }

    &__label {
      max-width: 487px;
    }

    &.setup-c {
      & > .learning-hub-card-img {
        background: url(./assets/setup-1660.png) no-repeat center center;
      }
    }
    &.q-for-email-c {
      & > .learning-hub-card-img {
        background: url(./assets/q-for-email-1660.png) no-repeat center center;
      }
    }
    &.soundwave-c {
      & > .learning-hub-card-img {
        background: url(./assets/soundwave-1660.png) no-repeat center center;
      }
    }
    &.temper-c {
      & > .learning-hub-card-img {
        background: url(./assets/temper-1660.png) no-repeat center center;
      }
    }
  }

  @media screen and (min-width: 1540px) and (max-width: 1659px) {
    min-width: 447px;
    max-width: 447px;

    &-img {
      min-width: 447px;
      max-width: 447px;
    }

    &__label {
      min-width: 447px;
      max-width: 447px;
    }

    &.setup-c {
      & > .learning-hub-card-img {
        background: url(./assets/setup-1540.png) no-repeat center center;
      }
    }
    &.q-for-email-c {
      & > .learning-hub-card-img {
        background: url(./assets/q-for-email-1540.png) no-repeat center center;
      }
    }
    &.soundwave-c {
      & > .learning-hub-card-img {
        background: url(./assets/soundwave-1540.png) no-repeat center center;
      }
    }
    &.temper-c {
      & > .learning-hub-card-img {
        background: url(./assets/temper-1540.png) no-repeat center center;
      }
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1539px) {
    min-width: 636px;
    max-width: 636px;

    &-img {
      min-width: 636px;
      max-width: 636px;
    }

    &__label {
      min-width: 636px;
      max-width: 636px;
    }

    &.setup-c {
      & > .learning-hub-card-img {
        background: url(./assets/setup-1440.png) no-repeat center center;
      }
    }
    &.q-for-email-c {
      & > .learning-hub-card-img {
        background: url(./assets/q-for-email-1440.png) no-repeat center center;
      }
    }
    &.soundwave-c {
      & > .learning-hub-card-img {
        background: url(./assets/soundwave-1440.png) no-repeat center center;
      }
    }
    &.temper-c {
      & > .learning-hub-card-img {
        background: url(./assets/temper-1440.png) no-repeat center center;
      }
    }
  }

  @media screen and (min-width: 1280px) and (max-width: 1439px) {
    min-width: 556px;
    max-width: 556px;

    &-img {
      min-width: 556px;
      max-width: 556px;
    }

    &__label {
      min-width: 556px;
      max-width: 556px;
    }

    &.setup-c {
      & > .learning-hub-card-img {
        background: url(./assets/setup-1280.png) no-repeat center center;
      }
    }
    &.q-for-email-c {
      & > .learning-hub-card-img {
        background: url(./assets/q-for-email-1280.png) no-repeat center center;
      }
    }
    &.soundwave-c {
      & > .learning-hub-card-img {
        background: url(./assets/soundwave-1280.png) no-repeat center center;
      }
    }
    &.temper-c {
      & > .learning-hub-card-img {
        background: url(./assets/temper-1280.png) no-repeat center center;
      }
    }
  }

  @media screen and (max-width: 1279px) {
    min-width: 422px;
    max-width: 422px;

    &-img {
      min-width: 422px;
      max-width: 422px;
    }

    &__label {
      min-width: 422px;
      max-width: 422px;
    }

    &.setup-c {
      & > .learning-hub-card-img {
        background: url(./assets/setup-1920.png) no-repeat center center;
      }
    }
    &.q-for-email-c {
      & > .learning-hub-card-img {
        background: url(./assets/q-for-email-1920.png) no-repeat center center;
      }
    }
    &.soundwave-c {
      & > .learning-hub-card-img {
        background: url(./assets/soundwave-1920.png) no-repeat center center;
      }
    }
    &.temper-c {
      & > .learning-hub-card-img {
        background: url(./assets/temper-1920.png) no-repeat center center;
      }
    }
  }
}
