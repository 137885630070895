.reply-email {
  width: 100%;
  min-height: 56px;
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  border-right: 1px solid #e3e7ed;
  border-bottom: 1px solid #e3e7ed;
  box-shadow: 0px 0px 12px 1px rgba(71, 107, 132, 0.15);
  animation: fadeIn 0.6s linear;

  &.not-connected {
    align-items: center;
    justify-content: space-between;
  }

  &__textarea {
    width: 100%;
    position: relative;

    &-plus-icon {
      position: absolute;
      cursor: pointer;
      top: 21px;
      left: 0;
      transform: translate(0, -50%);

      & path {
        transition: all 0.15s linear;
      }

      &:hover:not(.disabled) {
        & path {
          fill: #537bff;
        }
      }
    }

    &-loader {
      position: absolute;
      cursor: pointer;
      top: 21px;
      right: 0;
      transform: translate(0, -50%);
    }

    &-control {
      padding: 12px 12px 10px 0px;
      border-radius: 8px;
      width: calc(100% - 16px);
      min-height: 41px;
      height: 41px;
      max-height: 300px;
      border: 1px solid transparent;
      text-align: left;
      color: #070b12;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      resize: none;

      &:focus {
        border: 1px solid transparent;
      }

      &::placeholder {
        color: #94a3b8;
        padding-top: 0px;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-10px, -46%);
      }
    }
  }

  &-send-icon {
    cursor: pointer;
    margin-bottom: 15px;
    // transition: all 0.2s linear;
    & path {
      transition: all 0.15s linear;
    }

    &.active {
      & path {
        fill: #537bff;
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.15;
    }

    &:hover:not(.disabled) {
      & path {
        fill: #446fff;
      }
    }
  }

  &__loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 24px;
    color: #646f87;
    text-align: center;
    margin-bottom: 27px;
    animation: fadeIn 0.2s linear;

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      right: 2px;
      width: 20px;
      height: 20px;
      z-index: 9;
      animation: replyLoaderSpinning 1s linear infinite;

      @keyframes replyLoaderSpinning {
        0% {
          transform: translate(-60%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-60%, -50%) rotate(360deg);
        }
      }
    }
  }

  &-plus-icon {
    cursor: pointer;
    margin-bottom: 15px;

    &.disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.15;
    }
  }

  &__not-connected {
    display: flex;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #ad6e00;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    background: #fffcf5;

    &-icon {
      cursor: default;
    }

    &-text {
      cursor: default;
    }

    &-link {
      color: #537bff;
      transition: all 0.2s linear;

      &:hover {
        cursor: pointer;
        color: #0e37bd;
        text-decoration: underline;
      }
    }
  }
}
