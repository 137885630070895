.chrome-extension-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 8px;
    color: #FFFFFF;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    background: url(./assets/bg-left.svg) no-repeat left top, url(./assets/bg-right.svg) no-repeat right top, #537BFF;

    &__content-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
    }

    &__close-button {
        appearance: none;
        border: none;
        padding: 0;
        background-color: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid #FFFFFF;

        &:hover {
            color: #FFFFFF;
        }

        & > svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }
}
