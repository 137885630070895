@import './components/SubscriptionInfo/subscription-info';
@import './components/InvoicesList/invoices-list';

.billing {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: $black-new-design;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border: 1px solid #E3E7ED;
    border-radius: 16px;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $gray-300;
  }

  &__modal {
    & .sound-wave-modal__dialog-content {
      gap: 16px;
    }
  }

  &__modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; 
  }

  &__modal-textarea {
    height: 128px;
  }
}
