.add-chrome-extension {
    & .sound-wave-modal-header {
        font-size: 16px;
    }

    & .sound-wave-modal__dialog-content {
        padding: 16px 24px;
    }

    & .sound-wave-modal-footer {
        padding: 16px 32px;
        border: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__item {
        display: flex;
        gap: 16px;
        font-size: 14px;
        line-height: 24px;
        color: $gray-300;
    }

    &__item-icon {
        width: 32px;
        min-width: 32px;
        height: auto;
    }

    &__item-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__item-title {
        color: $black-new-design;
        font-weight: 500;
        line-height: 28px;
    }
}
