.existing-contact-disclaimer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #e3e7ed;
  border-radius: 4px;
  background-color: #f5f7ff;
  padding: 8px;
  font-size: 12px;
  line-height: 20px;
  color: $black-new-design;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 196px;

  &__accounts-amount {
    color: #537bff;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    padding: 2px 4px;
    cursor: pointer;
  }
}
