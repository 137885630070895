// colors

$white: #ffffff;

$text-low-emp: #e0e2ee;
$text-med-emp: #646c83;
$text-high-emp: #4d4558;
$text-new-emp: #878fa5;

$primary-color: #4eb4ff;
$primary-color-hover: #2f86c7;
$primary-color-active: #ffffff;

$primary-shade25: #d3ecff;
$primary-shade50: #a6d9ff;
$primary-shade75: #7ac7ff;

$primary-variant: #bee3ff;
$primary-variant-hover: #aed5f2;
$primary-variant-active: #ffffff;

$secondary-color: #365798;
$secondary-color-hover: #243f73;
$secondary-color-active: #ffffff;

$secondary-shade25: #cdd5e5;
$secondary-shade50: #9aabcb;
$secondary-shade75: #6881b2;

$success: #32b76c;
$success-hover: #268c53;
$success-active: #ffffff;

$warn: #f3cb3d;
$warn-hover: #e2bb30;
$warn-active: #372f0f;

// $error: #f26065;
$error: #c32c2c;
$error-hover: #cb4448;
$error-active: #ffffff;

$green: #62d694;
$green-hover: #48af75;
$grey: #acacb7;
$grey-hover: #9191a1;
$dark-grey: #646c83;
$dark-grey-hover: #434b62;
$yellow: #f3cb3d;
$light-purple: #9da7d0;
$popup-overlay: #38128a;

$text-primary: #414042;
$blue-primary: #29335c;
$green-primary: #32b76c;
$blue-secondary: #394577;
$red-primary: #fb7378;

$yellow-new-design: #ffb200;
$black-new-design: #070b12;
$gray-100: #f1f4f8;
$gray-200: #95a1b6;
$gray-300: #646f87;
$gray-400: #44536a;
$color-prospect: #ee72a3;
$color-user: #5243fa;
$color-unassigned: #646f87;

// background-colors

$sidebar-background: #1e1e1e;
$main-background: #f8f8fb;
$second-background: #333438;
$tertiary-background: #edf0f4;
$tertiary-background-hover: #e0e7f0;
$surface-white-background: #ffffff;
$surface-elevated-background: #f5f5ff;
$popup-overlay-background: rgba(102, 108, 113, 0.4);
$opacity06-background: rgba(77, 69, 88, 0.06);
$success-background: #b7e6cc;
$warn-background: #fbedbb;
$error-background: #fac7c9;

// background

$purple-gradient-background: linear-gradient(
  103.85deg,
  #381289 23.68%,
  #a635c6 75.19%,
  #c591d3 100%
);

$linear-blue-gradient-background: linear-gradient(
  180deg,
  #4eb4ff 0%,
  rgba(175, 221, 255, 0.4) 100%
);

$black-to-white-gradient-background: linear-gradient(
  90deg,
  #000000 1.32%,
  #c1c1c1 100%
);

// screen-sizes

$smaller: 320px;
$mob: 576px - 1;
$mobile: 768px - 1;
$tablet: 992px - 1;
$laptop: 1166px;
$desktop1280: 1280px;
$desktop1440: 1440px;
$desktop1660: 1660px;
$desktop2000: 2000px;

// animation

$fadein: animationFadeIn 0.4s ease-in;
@keyframes animationFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// mixins

@mixin userSelectDisable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@mixin userDragDisable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
